@import '../../../../variables.scss';

.graph-settings { 
  width: 100%;
  background-color: $grey1;

  border-radius: $border-radius-main-content;

  font-size: $fontsize-main-content;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  line-height: 1.1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
  'left middle right';
  grid-gap: 0.2rem;
}

select {
  font-size: $fontsize-main-content;
}

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;

  background-color: $grey1;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 0.05em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type=checkbox]::before {
  content: '';
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $color3;  
  
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type=checkbox]:checked::before {
  transform: scale(1);
}

.settings-title {
  text-align: center;
}

// LEFT SETTINGS COLUMN:

#left {
  grid-area: left;  
  
  box-sizing: border-box;
  padding: $padding-graph-settings;
}

#week-from {
  display: flex;
  justify-content: space-between;
}

#week-until {
  display: flex;
  justify-content: space-between;
}

#week-focus-check {
  display: flex;
  justify-content: space-between;
  margin: 0 0 3px 0;
}

#week-focus {
  display: flex;
  justify-content: space-between;
}

// MIDDLE SETTINGS COLUMN:

#middle {
  grid-area: middle;
  border-left: 5px solid $grey2;
  border-right: 5px solid $grey2;

  box-sizing: border-box;
  padding: $padding-graph-settings;
}

.day-selector {
  display: flex;
  justify-content: space-between;
}

.day-selector + .day-selector {
  margin-top: 0.2em;
}

.day-color-bar {
  height: 5px;
  margin: auto 10px;
  flex-grow: 1;
}

// RIGHT SETTINGS COLUMN:

#right {
  grid-area: right;
  
  box-sizing: border-box;
  padding: $padding-graph-settings;
}

