@import '../../../../variables.scss';

.graph-wrapper {
  width: 100%;
  margin-top: $margin-graph-settings;
  box-sizing: border-box;
  
  background-color: $grey1;
  border-radius: $border-radius-main-content;

  font-size: $fontsize-main-graph-title;
  text-align: center;
  flex-grow: 1;
}

.main-graph {
  width: 100%;
  height: calc(100% - 100px);

  font-size: 22px;

  margin-top: $margin-main-content;
}

.graph-title {
  padding: $padding-graph-title;
  display: flex;
  flex-direction: row;
}

.graph-tooltip .graph-tooltip-text {
  visibility: hidden;

  width: $width-main-graph-tooltip;
  padding: $padding-tooltip;
  background-color: $grey2;
  border-radius: $border-radius-main-content;
  font-size: $fontsize-main-content;
  font-weight: normal;
  
  position: absolute;
  z-index: 1;
}

.graph-tooltip:hover .graph-tooltip-text {
  visibility: visible;
}