@import '../../variables.scss';

.home {

  margin: $margin-main-content;
  padding: $padding-main-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}