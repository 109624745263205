@import '../../variables.scss';

.welcome {
  width: 100%;
  background-color: $grey1;

  border-radius: $border-radius-main-content;

  font-size: $fontsize-main-content;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  text-align: center;

  color: $grey2;
}

h1 {
  color: black;
  margin: 2vh 0 0 0;
}

p {
  margin: 0 0 2vh 0;
}