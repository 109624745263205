@import '../../../../variables';

Link {
  all: none;
}

.user-stats-tile {
  font-size: $fontsize-usertile-text;
  font-weight: bold;

  border-radius: $border-radius-user-tile;
  border: 2px solid $color1;

  &-row {
  padding: $padding-user-tile-row;

  color: $color3;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;


    &-stats {
      color: black;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &-raw {
        text-align: center;
        font-weight: normal;
        width: 1.75vw;
      }

      &-raw + &-raw {
        border-left: 1px solid $grey2;
      }

      &-graph {
        width: 10vw;
      }
    }

  }

  &-row + &-row {
    border-top: 1px solid $color1;
  }

  &:hover {
    background: $grey2;
  }

}