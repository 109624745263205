@import '../../variables.scss';

.sidebar {
  background-color: $color3;
  color: $sidebar-text-color;
  font-size: 32px;
  font-weight: bold;
  text-align: left;

  padding-top: 3px;

  display: flex;
  flex-direction: column;
}

.sidebar-category {
  color: $grey1;
  background: none;
  font-size: $fontsize-sidebar-main;
  text-decoration: none;

  padding: $padding-sidebar-items;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: center;

  &:hover {
    background-color: $color1;
    transition: background-color 0.4s;
  }
}

.sidebar-main-link {
  color: $grey1;
  background: none;
  font-size: $fontsize-sidebar-main;
  text-decoration: none;

  padding: $padding-sidebar-items;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: center;

  &:hover {
    background-color: $color1;
    transition: background-color 0.4s;
  }
}

.sidebar-child-link {
  color: $grey1;
  background: none;
  font-size: $fontsize-sidebar-child;
  text-decoration: none;

  padding: $padding-sidebar-items;
  margin: $margin-sidebar-child;
  box-sizing: border-box;

  &:hover {
    background-color: $color1;
    transition: background-color 0.4s;
  }
}

.disabled {
  pointer-events: none;
  text-decoration: line-through;
}

#active {
  background-color: $color2;

  &:hover {
    background-color: $color1;
  }
}