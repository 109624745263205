// ALL THE COLOURS USED IN ALL SCSS

$color1: #0ab8cf;
$color2: #0998ad;
$color3: #077f8f;

$grey1: #fafafa;
$grey2: #aabdbf;
$grey3: #aaa;

$sidebar-text-color: white;

$active-button-text-color: white;

// WIDTH AND HEIGHT VALUES USED
$width-main-graph-tooltip: 30vw;

// FONT-SIZES USED:
$fontsize-sidebar-header: 1.5rem;
$fontsize-sidebar-main: 1.2rem;
$fontsize-sidebar-child: 0.9rem;

$fontsize-main-content: 0.9rem;
$fontsize-main-title: 1.5rem;

$fontsize-main-graph-title: 1.2rem;
$fontsize-main-graph-title-icon: 0.75rem;

$fontsize-usertile-text: 0.8rem;

// MARGINS & PADDINGS USED:
$margin-main-content: 10px;

$margin-graph-settings: 10px;

$margin-sidebar-child: 0 0 0 2vw;

$padding-topbar: 0px 10px;

$padding-main-content: 0px;
$padding-main-title: 10px;
$padding-main-columns: 10px;

$padding-user-tile-row: 0px 10px;

$padding-graph-tile: 5px 10px;
$padding-graph-settings: 10px;
$padding-graph-title: 0px 10px;

$padding-tooltip: 10px;

$padding-sidebar-items: 0.3vw 0.3vw 0.3vw 1vw;


// BORDER PROPERTIES USED:
$border-radius-main-content: 10px;
$border-radius-user-tile: 5px;