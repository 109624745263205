@import '_variables.scss';

body {
    background-color: $grey2;
}

.app {
    width: 100vw;
    height: 100vh;

    font-weight: bold;

    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-template-rows: 0.1fr 2fr;
    grid-template-areas:
        'header topbar'
        'sidebar main';
}

#header {
    grid-area: header;

    background-color: $color2;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  
    justify-content: center;
    align-items: center;

    background-color: $color2;
    color: $sidebar-text-color;
    font-size: $fontsize-sidebar-header;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    line-height: 50%;
  
}

#sidebar {
    grid-area: sidebar;

    background-color: $color3;
    color: $sidebar-text-color;
    font-size: $fontsize-sidebar-main;
    font-weight: bold;
    text-align: center;
  
}

#topbar {
    grid-area: topbar;
}

#main {
    grid-area: main;
}