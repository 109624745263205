@import '../../../../variables.scss';

.graph-type-selector {
  width: 100%;
  margin-top: $margin-main-content;

  box-sizing: border-box;

  font-size: $fontsize-main-content;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.graph-selector {
  font-size: $fontsize-main-content;
  font-weight: bold;
  text-align: center;
  height: calc(3 * $fontsize-main-content);

  background-color: $grey1;
  border: none;
  border-radius: $border-radius-main-content;

  flex-grow: 1;
  transition: background-color 0.4s, color 0.2s;
}

.graph-selector + .graph-selector {
  margin: 0px 0px 0px 10px;
}

.graph-selector:hover {
  background-color: $color2;
  color: $active-button-text-color;

  transition: background-color 0.2s, color 0.2s;
}

.active {
  background-color: $color3;
  color: $active-button-text-color;

  transition: background-color 0.2s;
}