@import '../../../variables';

* {
  box-sizing: border-box;
}

.unity-dash {
  margin: $margin-main-content;
  padding: $padding-main-content;
  border-radius: $border-radius-main-content;

  overflow-x: hidden;
  overflow-y: auto;

  background-color: $grey1;

  display: flex;
  flex-direction: column;

  &-title {
    padding: $padding-main-title;
    border-bottom: 3px solid $grey2; 
    border-radius: $border-radius-main-content $border-radius-main-content 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: $grey1;

    // Dumbest calculation ever, but position:fixed fucks with the width.
    width: calc(100% - $margin-main-content - $margin-main-content - 23.0769vw);
    position: fixed;
    z-index: 1;
  }

  &-week-selector {
    display: flex;
    flex-direction: row;
  }
 
  &-day-tiles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 3px solid $grey2;
    margin-top: 44px;
  }


}

.day-tile {
  flex-grow: 1;
  text-align: center;
  padding: $padding-graph-tile;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.day-tile + .day-tile {
  border-left: 3px solid grey;
}

.unity-dash-main-container {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  width: 100%;

}

.unity-dash-main-item {
  margin: $margin-main-content;
  width: 47%;
}