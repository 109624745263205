@import '../../../variables.scss';

.main-view {
  background-color: $grey2;
  margin: $margin-main-content;
  padding: $padding-main-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}